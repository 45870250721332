import React, {useEffect} from 'react';
import useGetAvailablePromotionsQuery from '@mgp-fe/shared/core-api/queries/cart/available-promotions.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import {AvailablePromotionsCollectionModel} from '@mgp-fe/shared/core-api/domain/promotions.ts';
import useApplyPromotionMutation from '@mgp-fe/shared/core-api/mutations/cart/apply-promotion.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import useRemovePromotionsFromCartMutation from '@mgp-fe/shared/core-api/mutations/cart/remove-promotions.ts';
import useGetPromotionDetailedQuery from '@mgp-fe/shared/core-api/queries/promotions/detailed.ts';

export default function AvailablePromotions() {
	const availablePromotions = useGetAvailablePromotionsQuery();
	const myCart = useCartMyQuery();

	useEffect(() => {
		if (myCart.isFetching) availablePromotions.refetch();
	}, [myCart]);

	return <section className='grid md:grid-cols-2 lg:grid-cols-3 gap-small py-small mx-auto w-full'>
		{
			availablePromotions.data?.data['hydra:member'].map(p => <PromotionRulesCard key={p.id} promotion={p}
				cart={myCart.data?.data}/>)
		}
	</section>;
}

function PromotionRulesCard(props: PromotionRulesCardProps) {
	const products = useProductsListQuery({});
	const promotionDetailed = useGetPromotionDetailedQuery({id: props.promotion.id});

	const applyPromotionMutation = useApplyPromotionMutation();
	const removePromotionsMutation = useRemovePromotionsFromCartMutation();

	const rulesProducts = props.promotion?.rules && props.promotion?.rules.specific_products_in_cart?.products.map(i => {
		return products.data?.data['hydra:member'].find(p => p.id === i);
	});

	const rulesProductsWithMinimumQuantity = props.promotion?.rules && props.promotion?.rules.specific_products_in_cart_with_minimum_quantity?.map(i => {
		return {
			...products.data?.data['hydra:member'].find(p => p.id === i.product), quantity: i.quantity,
		};
	});

	const rulesProductsWithExactQuantity = props.promotion?.rules && props.promotion?.rules.specific_products_in_cart_with_exact_quantity?.map(i => {
		return {
			...products.data?.data['hydra:member'].find(p => p.id === i.product), quantity: i.quantity,
		};
	});

	const promotionApplied = !props.cart?.order.promotions.filter(p => p.id === props.promotion.id).length;

	return <Card key={props.promotion.id} className='p-5 flex flex-col w-full mx-auto'>
		<h5 className='text-4xl uppercase'>{props.promotion.name} promotion</h5>
		<p className='pb-small text-secondary text-lg font-semibold pt-small'>To get discount
			<ul className='list-disc pl-small'>
				{promotionDetailed.data?.data.actions.map(a => {
					let discount = '';
					if (a.absoluteDiscount) {
						discount = '$' + a.absoluteDiscount;
					} else {
						discount = a.percentageDiscount + '%';
					}

					if (a.forProducts.length) return a.forProducts.map(fp => <li
						key={fp.id}>{fp.name} -{discount}</li>);
					return <li key={a.id}>{discount} Off your entire shopping cart </li>;
				})}
			</ul>
		</p>
		<p className='text-secondary text-lg font-semibold'>You must comply with these conditions</p>
		{props.promotion.rules.specific_products_in_cart?.products.length ? <>
			<ul className='list-disc pl-small'>
				{rulesProducts?.map(r =>
					<li className='text-light' key={r?.id}>{r?.name}<span
						className='text-light/50'> in shopping cart</span></li>)}
			</ul>
		</> : ''}
		{props.promotion.rules.specific_products_in_cart_with_minimum_quantity?.length ? <>
			<ul className='list-disc pl-small'>
				{rulesProductsWithMinimumQuantity?.map(r =>
					<li className='text-light' key={r.id}>
						<span className='text-light/50'>At least </span>
						{r.name}: {r.quantity}x
						<span className='text-light/50'> in shopping cart</span>
					</li>)}
			</ul>
		</> : ''}
		{props.promotion.rules.specific_products_in_cart_with_exact_quantity?.length ? <>
			<ul className='list-disc pl-small'>
				{rulesProductsWithExactQuantity?.map(r =>
					<li className='text-light' key={r.id}>
						<span className='text-light/50'>Exactly </span>
						{r.name}: {r.quantity}x
						<span className='text-light/50'> in shopping cart</span>
					</li>)}
			</ul>
		</> : ''}
		{props.promotion.rules.number_of_items_in_cart ? <>
			<ul className='list-disc pl-small'>
				<li className='text-light'>
					<span>At least </span>
					{props.promotion.rules.number_of_items_in_cart.itemsCount}
					<span className='text-light/50'> products in shopping cart</span>
				</li>
			</ul>
		</> : ''}
		{props.promotion.rules.minimum_cart_amount ? <div className='flex text-light'>
			<ul className='list-disc pl-small pb-5'>
				<li className='text-light'>
					<span className='text-light/50'>Amount of order more than </span>
                    ${props.promotion.rules.minimum_cart_amount.minimumAmount}
				</li>
			</ul>
		</div> : ''}

		{promotionApplied ?
			<Button
				onClick={() => applyPromotionMutation.mutate({promotionId: props.promotion.id})}
				disabled={!props.promotion.cartPassedRulesCheck}
				state={applyPromotionMutation.status}
				className='h-14 mt-auto'
				size={'md'}
			>
                Apply
			</Button>
			:
			<Button
				onClick={() => removePromotionsMutation.mutate({promotions: [props.promotion.id]})}
				state={removePromotionsMutation.status}
				className='h-14 mt-auto'
				variant='destructive'
				size={'md'}
			>
                Remove
			</Button>}

	</Card>;
}

interface PromotionRulesCardProps {
    promotion: AvailablePromotionsCollectionModel
    cart?: CartModel;
}