import React from 'react';
import {
	cancellableStates,
	ScanRequestCollectionModel,
	ScanRequestState,
} from '@mgp-fe/shared/core-api/domain/scan-request.ts';
import {Card, CardContent, CardFooter} from '@mgp-fe/shared/ui/card.tsx';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {Badge, badgeVariants} from '@mgp-fe/shared/ui/badge.tsx';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cn} from '@mgp-fe/shared/utils';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';
import PhoneNumberFormatted from '@mgp-fe/shared/modules/formatters/PhoneNumberFormatted.tsx';
import useTransitByIriMutation from '@mgp-fe/shared/core-api/mutations/change-state/change-transit-by-iri.ts';
import {useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';

export default function ScanRequestCard({scanRequest, showCustomer}: ScanRequestCardProps) {
	const {user} = useAuth<User>();
	const queryClient = useQueryClient();
	const updateScanRequestMutation = useTransitByIriMutation({
		onSuccess: () => {
			queryClient.invalidateQueries(keysResolver(endpoints.scanRequests.list));
		},
	});

	return <Card variant='secondary' className='w-full flex flex-col'>
		<CardContent className='pt-6 flex flex-col gap-4 grow'>
			<div className='flex justify-between items-center'>
				{scanRequest.requestedForDate
					? <span>
						<FontAwesomeIcon icon='calendar-check' className='text-secondary-200 mr-3'/>
						<DateFormatted showDateOnly date={scanRequest.requestedForDate}/>
					</span>
					: <span className='text-sm !text-warning'>Date not specified</span>}

				<Badge variant={ScanRequestStateBadgeVariantMap[scanRequest.state]}>
					{humanizeEnumValue(scanRequest.state)}
				</Badge>
			</div>

			<h5 className='col-span-2 w-full text-secondary-200'>
				{scanRequest.partnerLocation.name || <span className='text-destructive'>Missing partner location</span>}
			</h5>
			{showCustomer ? <div className='flex flex-wrap items-center gap-mini'>
				<FontAwesomeIcon icon='user' className='text-secondary-200'/>
				<span>{scanRequest.customer?.name || <span className='text-warning'>Missing user</span>}</span>
				<PhoneNumberFormatted phoneNumber={scanRequest.customer?.phone} link={true} missingLabel='No phone'/>
				<a href={`mailto:${scanRequest.customer?.email}`}>{scanRequest.customer?.email}</a>
			</div> : ''}
		</CardContent>

		<CardFooter className='flex-wrap'>
			<span className='w-1/2 text-xs'>
				Created <br/> <DateFormatted date={scanRequest.createdAt || new Date()}/>
			</span>
			<span className='w-[calc(50%-1rem)] text-xs'>
				Updated <br/>
				{scanRequest.updatedAt ? <> <DateFormatted date={scanRequest.updatedAt}/></>
					: <span className='text-warning'>any updates yet</span>}
			</span>
			<Button
				onClick={() => updateScanRequestMutation.mutate({
					iri: scanRequest['@id'],
					transition: 'cancel_by_customer',
				})}
				state={updateScanRequestMutation.status}
				variant='link'
				className={cn('text-destructive-500', !cancellableStates.includes(scanRequest.state) || user?.['@type'] === 'Partner' ? 'invisible' : '')}
				size='sm'
				icon={<FontAwesomeIcon icon='xmark'/>}>
                Cancel
			</Button>
			{user?.['@type'] === 'Partner' ? <div className='w-full flex justify-between flex-wrap gap-small'>
				<Button
					onClick={() => updateScanRequestMutation.mutate({
						iri: scanRequest['@id'],
						transition: 'accept',
					})}
					state={updateScanRequestMutation.status}
					variant='default'
					disabled={!cancellableStates.includes(scanRequest.state) || scanRequest.state === 'accepted'}
					size='sm'
					icon={<FontAwesomeIcon icon='xmark'/>}>
                    Accept
				</Button>
				<Button
					onClick={() => updateScanRequestMutation.mutate({
						iri: scanRequest['@id'],
						transition: 'reject',
					})}
					state={updateScanRequestMutation.status}
					variant='destructive'
					disabled={!cancellableStates.includes(scanRequest.state)}
					size='sm'
					icon={<FontAwesomeIcon icon='xmark'/>}>
                    Reject
				</Button>
				<Button
					onClick={() => updateScanRequestMutation.mutate({
						iri: scanRequest['@id'],
						transition: 'execute',
					})}
					state={updateScanRequestMutation.status}
					variant='link'
					disabled={scanRequest.state !== 'accepted'}
					size='sm'
					icon={<FontAwesomeIcon icon='check'/>}>
                    Done
				</Button>
			</div> : ''}
		</CardFooter>
	</Card>;
}

export function ScanRequestCardSkeleton() {
	return <Card variant='secondary' className='w-full flex flex-col'>
		<CardContent className='pt-6 flex flex-col gap-4 grow'>
			<div className='flex justify-between items-center'>
				<div className='flex items-center gap-2'>
					<Skeleton className='w-5 h-5 rounded-full'/>
					<Skeleton className='w-1/4 h-4'/>
				</div>

				<Skeleton className='w-32 h-5 rounded-md'/>
			</div>

			<Skeleton className='h-4 w-3/4'/>
		</CardContent>

		<CardFooter className='flex justify-between items-center'>
			<div className='w-1/2'>
				<Skeleton className='h-4 w-3/4'/>
			</div>

			<div className='w-[calc(50%-1rem)]'>
				<Skeleton className='h-4 w-3/4'/>
			</div>

			<Skeleton className='w-24 h-8 rounded-md'/>
		</CardFooter>
	</Card>;
}

interface ScanRequestCardProps {
    scanRequest: ScanRequestCollectionModel;
    scansRequestsRefetch?: () => void;
    showCustomer?: boolean;
}

const ScanRequestStateBadgeVariantMap = {
	requested: 'warning',
	accepted: 'default',
	rejected: 'destructive',
	'canceled_by_customer': 'destructive',
	executed: 'success',
} as Record<ScanRequestState, keyof typeof badgeVariants>;
