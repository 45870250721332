import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';

export default function useTransitByIriMutation<T>(props: UseTransitByIriMutationProps<T>) {
	return useMutation<AxiosResponse<T>, AxiosError<HydraError>, TransitByIdParams>({
		mutationKey: keysResolver(endpoints.transit(''), 'patch', props),
		mutationFn: (params) => coreApiClient.patch<T>(endpoints.transit(params.iri), {
			transition: params.transition,
			stateName: params.stateName,
		}),
		...props,
	});
}

type UseTransitByIriMutationProps<T> = UseMutationOptions<
    AxiosResponse<T>,
    AxiosError<HydraError>,
    TransitByIdParams
>

export interface TransitByIdParams {
    iri: Iri;
    transition: string;
    stateName?: string;
}
